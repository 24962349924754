//
// Card
// --------------------------------------------------


// Hover effect

.card-hover {
  position: relative;
  transition: $card-transition;
  overflow: hidden;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $white;
    transition: all .38s;
    opacity: 0;
    z-index: 1;
    content: '';
  }

  .card-header,
  .card-body,
  .card-footer {
    position: relative;
    z-index: 5;
  }

  .card-title,
  .card-text,
  hr {
    transition: color .25s, opacity .25s;
  }

  &:hover, &.show {
    &::before {
      width: 100%;
      height: 100%;
      opacity: 1;
      transform: translateY(-50%) translateX(-50%) scale(1.5);
    }

    .card-title { color: $gray-900; }
    .card-text { color: $gray-700; }

    hr {
      color: $border-color;
      opacity: 1;
    }
  }
}


// Link hover effect

.card-hover-link {
  text-decoration: none;

  .card-title {
    margin-bottom: $spacer * 2.5;
  }

  .card-link {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-weight: $font-weight-medium;
    opacity: 0;
    transition: all .35s;
  }

  &:hover {
    .card-link {
      bottom: $card-spacer-y;
      opacity: 1;
    }
  }

  @include media-breakpoint-down(lg) {
    .card-link {
      bottom: $card-spacer-y;
      opacity: 1;
    }
  }
}
