//
// Variables
// --------------------------------------------------


// Import Bootstrap functions to use inside variables values
@import '../vendor/bootstrap/scss/functions';


//== Colors
//
// Gray and brand colors to use across the Theme.

// Grayscale
$white:                       #fff !default;
$gray-100:                    #f5f7fc !default;
$gray-200:                    #e5e7ef !default;
$gray-300:                    #d5d7e1 !default;
$gray-400:                    #c5c7d4 !default;
$gray-500:                    #a4a8b9 !default;
$gray-600:                    #747891 !default;
$gray-700:            	      #4a4c63 !default;
$gray-800:                    #202034 !default;
$gray-900:                    #151525 !default;
$black:                       #000 !default;

// Brand colors
$primary:                     #ff444e !default;
$secondary:                   $gray-200 !default;
$info:		                    #4c82f7 !default;
$success:                     #45cea7 !default;
$warning:                     #ffba08 !default;
$danger:                      #ef4444 !default;
$light:                       $white !default;
$dark:                        $gray-900 !default;

// Adding new color to default Bootstrap's $theme-colors map
$theme-colors: (
  'primary':                  $primary,
  'secondary':                $secondary,
  'success':                  $success,
  'info':                     $info,
  'warning':                  $warning,
  'danger':                   $danger,
  'light':                    $light,
  'dark':                     $dark
) !default;

// Faded colors map
$theme-faded-colors: (
  'primary':                  rgba($primary, .2),
  'success':                  rgba($success, .2),
  'info':                     rgba($info, .2),
  'warning':                  rgba($warning, .2),
  'danger':                   rgba($danger, .2),
  'light':                    rgba($light, .2),
  'dark':                     rgba($dark, .7)
) !default;

// User selection color
$user-selection-color:        rgba($primary, .22) !default;


// Options
//
// Modifying Bootstrap global options

$enable-caret:                false !default;
$enable-shadows:              true !default;
$enable-negative-margins:     true !default;
$enable-smooth-scroll:        false !default;


// Grid settings overrides
//
// Modifying default Bootstrap's $grid-breakpoints map
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  xxl: 1400px
) !default;


// Grid containers
//
// Modifying default Bootstrap's $container-max-widths map (max-width of .container)
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1320px
) !default;


// Spacing

$spacer:            1rem !default;
$grid-gutter-width: 24px !default;


// Add new space to default Bootstrap's $spacers map
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4
) !default;


// Body

$body-color:                                    rgba($white, .7) !default;
$body-bg:                                       $dark !default;
$text-muted:                                    rgba($white, .5) !default;


// Horizontal rule

$hr-margin-y:                                   $spacer * 1.5 !default;


// Headings
$headings-color:                                $white !default;


// Links

$link-color:                                    $primary !default;
$link-decoration:                               underline !default;
$link-hover-color:                              darken($link-color, 10%) !default;
$link-hover-decoration:                         none !default;
$link-transition:                               color .2s ease-in-out !default;
$emphasized-link-hover-darken-percentage:       0% !default;


// Components
//
// Define common padding and border radius sizes and more.

$border-width:                                  1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px
) !default;
$border-color:                                  $gray-300 !default;
$border-light-color:                            rgba($white, .25) !default;

$border-radius:                                 .5rem !default;
$border-radius-lg:                              .75rem !default;
$border-radius-sm:                              .25rem !default;

// Border colors map
$border-colors: (
  'primary':                                    rgba($primary, .1),
  'success':                                    rgba($success, .1),
  'info':                                       rgba($info, .1),
  'warning':                                    rgba($warning, .1),
  'danger':                                     rgba($danger, .1),
  'light':                                      $border-light-color,
  'dark':                                       rgba($dark, .8)
) !default;


// Theme shadows

$box-shadow-sm:                                 0 .25rem .75rem -.125rem rgba(#353846, .08) !default;
$box-shadow:                                    0 .25rem .75rem rgba(#353846, .08),
                                                0 .5rem 1.125rem rgba(#353846, .02) !default;
$box-shadow-lg:                                 0 1.5rem 1.875rem rgba(#353846, .16) !default;
$box-shadow-hover:                              0 1.5rem 1.875rem rgba($primary, .16) !default;
$box-shadow-inset:                              inset 0 1px 2px transparent !default;


// Z-index master list

$zindex-btn-scroll-top:                         1025 !default;
$zindex-offcanvas:                              1050 !default;
$zindex-gallery-backdrop:                       1050 !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$path-to-fonts:                                 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap' !default;

$font-family-sans-serif:                        'Inter', sans-serif !default;
$headings-color:                                $gray-900 !default;

$icons-font-family:                             'pyt-icons' !default;
$icons-font-path:                               '../fonts' !default;

// Import theme icon font set
@import                                         'components/icons';

$font-size-base:                                1rem !default;
$font-size-xl:                                  ($font-size-base * 1.25) !default;
$font-size-lg:                                  ($font-size-base * 1.125) !default;
$font-size-sm:                                  ($font-size-base * .875) !default;
$font-size-xs:                                  ($font-size-base * .75) !default;

$font-weight-normal:                            400 !default;
$font-weight-medium:                            500 !default;
$font-weight-semi-bold:                         600 !default;
$font-weight-bold:                              700 !default;

$line-height-base:                              1.5 !default;
$line-height-lg:                                1.5 !default;
$line-height-sm:                                1.5 !default;

$h1-font-size:                                  $font-size-base * 2.5 !default;
$h2-font-size:                                  $font-size-base * 2 !default;
$h3-font-size:                                  $font-size-base * 1.625 !default;
$h4-font-size:                                  $font-size-base * 1.375 !default;
$h5-font-size:                                  $font-size-base * 1.125 !default;
$h6-font-size:                                  $font-size-base !default;

$h1-line-height:                                1.25 !default;
$h2-line-height:                                1.3 !default;
$h3-line-height:                                1.35 !default;
$h4-line-height:                                1.4 !default;
$h5-line-height:                                1.45 !default;
$h6-line-height:                                1.5 !default;

$lead-font-size:                                $font-size-base * 1.5 !default;
$lead-font-weight:                              $font-weight-normal !default;

$headings-margin-bottom:                        ($spacer * 1.5) !default;
$headings-font-weight:                          $font-weight-semi-bold !default;

// Modifying default Bootstrap's $font-sizes map
$font-sizes: (
  1:                                            $h1-font-size,
  2:                                            $h2-font-size,
  3:                                            $h3-font-size,
  4:                                            $h4-font-size,
  5:                                            $h5-font-size,
  6:                                            $h6-font-size,
  'xl':                                         $font-size-xl,
  'lg':                                         $font-size-lg,
  'base':                                       $font-size-base,
  'sm':                                         $font-size-sm,
  'xs':                                         $font-size-xs
) !default;

// Display
$display1-size:                                 $font-size-base * 4.5 !default;
$display2-size:                                 $font-size-base * 4 !default;
$display3-size:                                 $font-size-base * 3.5 !default;
$display4-size:                                 $font-size-base * 3 !default;
$display5-size:                                 $font-size-base * 2.5 !default;
$display6-size:                                 $font-size-base * 2 !default;

// Modifying default Bootstrap's $display-font-sizes map
$display-font-sizes: (
  1: $display1-size,
  2: $display2-size,
  3: $display3-size,
  4: $display4-size,
  5: $display5-size,
  6: $display6-size
) !default;

$display-font-weight:                           $font-weight-bold !default;

$text-shadow:                                   0 .0625rem $dark !default;


// Tables

$table-cell-padding-y:                          .75rem !default;
$table-cell-padding-x:                          .75rem !default;
$table-cell-padding-y-sm:                       .5rem !default;
$table-cell-padding-x-sm:                       .5rem !default;
$table-hover-bg:                                $primary !default;
$table-hover-color:                             $white !default;
$table-active-bg:                               $primary !default;
$table-active-color:                            $white !default;


// Buttons + Forms

$input-btn-padding-y:                           $spacer * .5625 !default;
$input-btn-padding-x:                           $spacer * 1.125 !default;
$input-btn-font-size:                           $font-size-base !default;

$input-btn-padding-y-sm:                        $spacer * .425 !default;
$input-btn-padding-x-sm:                        $spacer * 1 !default;
$input-btn-font-size-sm:                        $font-size-sm !default;

$input-btn-padding-y-lg:                        $spacer * .75 !default;
$input-btn-padding-x-lg:                        $spacer * 1.25 !default;
$input-btn-font-size-lg:                        $font-size-lg !default;

$input-group-icon-size:                         $font-size-base !default;
$input-btn-focus-width:                         0 !default;


// Buttons

$btn-padding-x:                                 1.25rem !default;
$btn-padding-x-sm:                              1rem !default;
$btn-padding-x-lg:                              1.5rem !default;

$btn-box-shadow:                                unset !default;
$btn-focus-box-shadow:                          unset !default;
$btn-active-box-shadow:                         unset !default;

$btn-dark-color:                                $white !default;
$btn-light-color:                               $gray-700 !default;
$btn-font-weight:                               $font-weight-medium !default;

$btn-link-decoration:                           none !default;
$btn-link-hover-decoration:                     underline !default;

$btn-icon-size:                                 $font-size-base !default;
$btn-white-space:                               nowrap !default;
$btn-transition:                                color .25s ease-in-out, background-color .25s ease-in-out,
                                                border-color .25s ease-in-out, box-shadow .25s ease-in-out !default;

// Scroll to top button
$btn-scroll-top-size:                           2.75rem !default;
$btn-scroll-top-icon-font-size:                 $font-size-lg !default;
$btn-scroll-top-bg:                             rgba($gray-900, .25) !default;
$btn-scroll-top-hover-bg:                       rgba($gray-900, .5) !default;
$btn-scroll-top-color:                          $white !default;
$btn-scroll-top-hover-color:                    $white !default;
$btn-scroll-top-transition:                     right 400ms cubic-bezier(.68, -.55, .265, 1.55), opacity .3s, background-color .25s ease-in-out !default;


// Forms

$form-text-margin-top:                          .375rem !default;
$form-text-font-size:                           $font-size-xs !default;

$form-label-margin-bottom:                      .375rem !default;
$form-label-font-size:                          $font-size-sm !default;
$form-label-font-weight:                        $font-weight-medium !default;
$form-label-color:                              $gray-800 !default;

$input-bg:                                      rgba($white, .03) !default;
$input-box-shadow:                              0 0 0 0 transparent !default;
$input-border-width:                            $border-width !default;
$input-border-color:                            rgba($white, .25) !default;
$input-focus-border-color:                      rgba($white, .5) !default;
$input-focus-box-shadow:                        0 .375rem .625rem -.3125rem rgba($primary, .15) !default;
$input-transition:                              border-color .2s ease-in-out, box-shadow .2s ease-in-out !default;
$input-placeholder-color:                       rgba($white, .4) !default;

// Select
$form-select-indicator-color:                   $white !default;

// Checkboxes & radios
$form-check-label-color:                        $body-color !default;
$form-check-label-font-size:                    $font-size-sm !default;
$form-check-input-active-filter:                0 !default;

$form-check-input-bg:                           darken($gray-300, 1%) !default;
$form-check-input-border:                       1px solid darken($gray-300, 1%) !default;
$form-check-input-border-radius:                .375em !default;

$form-check-input-checked-color:                $white !default;
$form-check-input-checked-bg-color:             $primary !default;
$form-check-input-checked-border-color:         $primary !default;

$form-check-input-focus-border:                 $form-check-input-border !default;
$form-check-input-focus-box-shadow:             0 !default;

// Switches
$form-switch-color:                             $white !default;
$form-switch-width:                             2.25em !default;
$form-switch-height:                            1.25em !default;
$form-switch-focus-color:                       $form-switch-color !default;
$form-switch-bg:                                darken($gray-300, 2%) !default;
$form-switch-checked-bg:                        $primary !default;

// Select
$form-select-indicator-padding:                 $input-btn-padding-x * 2 !default;

// Range
$form-range-thumb-bg:                           $primary !default;
$form-range-thumb-active-bg:                    darken($form-range-thumb-bg, 9%) !default;

// Validation
$form-feedback-tooltip-opacity:                 .08 !default;
$form-feedback-icon-valid-color:                $success !default;
$form-feedback-icon-invalid-color:              $danger !default;
$form-feedback-tooltip-border-radius:           .3125rem !default;
$form-feedback-icon-valid-color:                $form-feedback-valid-color !default;
$form-feedback-icon-invalid-color:              $form-feedback-invalid-color !default;
$form-feedback-icon-valid:                      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-valid-color}'><path d='M10 1.667c-4.595 0-8.333 3.738-8.333 8.333S5.405 18.333 10 18.333s8.333-3.738 8.333-8.333S14.595 1.667 10 1.667zm0 15c-3.676 0-6.667-2.991-6.667-6.667S6.324 3.333 10 3.333 16.667 6.324 16.667 10 13.676 16.667 10 16.667zm-1.668-5.345L6.416 9.41 5.24 10.59l3.094 3.088 5.588-5.588-1.178-1.178-4.412 4.412z'/></svg>") !default;
$form-feedback-icon-invalid:                    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-invalid-color}'><path d='M7.643 13.535L10 11.178l2.357 2.357 1.178-1.178L11.178 10l2.357-2.357-1.178-1.178L10 8.822 7.643 6.465 6.465 7.643 8.822 10l-2.357 2.357 1.178 1.178zM10 18.333c4.595 0 8.333-3.738 8.333-8.333S14.595 1.667 10 1.667 1.667 5.405 1.667 10 5.405 18.333 10 18.333zm0-15c3.676 0 6.667 2.991 6.667 6.667S13.676 16.667 10 16.667 3.333 13.676 3.333 10 6.324 3.333 10 3.333z'/></svg>") !default;



// Input group

$input-group-addon-bg:                          $white !default;
$input-group-addon-font-weight:                 $font-weight-medium !default;


// Navbar

$navbar-padding-y:                              $spacer * 1.375 !default;
$navbar-nav-link-padding-x:                     $spacer !default;
$navbar-toggler-padding-x:                      $spacer * .5 !default;

// Navbar brand
$navbar-brand-padding-y:                        0 !default;
$navbar-brand-margin-end:                       $spacer * 1.5 !default;

// Navbar dark theme
$navbar-dark-color:                             rgba($white, .8) !default;
$navbar-dark-hover-color:                       $white !default;
$navbar-dark-active-color:                      $primary !default;


// Nav

$nav-link-padding-x:                            $spacer * .75 !default;
$nav-link-color:                                rgba($white, .8) !default;
$nav-link-hover-color:                          $white !default;
$nav-link-active-color:                         $white !default;
$nav-link-disabled-color:                       rgba($white, .5) !default;
$nav-link-font-weight:                          $font-weight-medium !default;


// Card

$card-spacer-y:                                 $spacer * 1.5 !default;
$card-spacer-x:                                 $spacer * 1.5 !default;
$card-bg:                                       transparent !default;
$card-cap-bg:                                   $card-bg !default;
$card-border-color:                             $border-light-color !default;
$card-transition:                               border-color .2s ease-in-out,
                                                background-color .2s ease-in-out,
                                                color .2s ease-in-out !default;
$card-title-spacer-y:                           .75rem !default;


// Icon box

$icon-box-width:                                4rem !default;
$icon-box-font-size:                            2.5rem !default;

$icon-box-lg-width:                             5.25rem !default;
$icon-box-lg-font-size:                         2.75rem !default;

$icon-box-sm-width:                             3rem !default;
$icon-box-sm-font-size:                         1.5rem !default;

$icon-box-border-radius:                        50% !default;
$icon-box-transition:                           box-shadow .22s ease-in-out,
                                                background-color .22s ease-in-out,
                                                color .22s ease-in-out !default;


// Carousel

// Dots
$carousel-dots-spacing-y:                       $spacer * 1.5 !default;
$carousel-dot-spacing-x:                        .25rem !default;
$carousel-dot-size:                             .375rem !default;
$carousel-dot-active-size:                      .75rem !default;
$carousel-dot-active-border-width:              .125rem !default;
$carousel-dot-transition:                       opacity .2s ease-in-out, transform .2s ease-in-out !default;
$carousel-dot-dark-bg:                          $primary !default;
$carousel-dot-dark-active-border-color:         $primary !default;
$carousel-dot-light-bg:                         $white !default;
$carousel-dot-light-active-border-color:        $white !default;

// Controls (prev/next buttons)
$carousel-control-spacing-y:                    $spacer * 1.75 !default;
$carousel-control-spacing-x:                    $spacer * 1.75 !default;
$carousel-control-size:                         2.75rem !default;
$carousel-control-border-width:                 $border-width !default;
$carousel-control-border-color:                 $border-light-color !default;
$carousel-control-border-radius:                $border-radius !default;
$carousel-control-font-size:                    $font-size-xl !default;
$carousel-control-color:                        $white !default;
$carousel-control-bg:                           transparent !default;
$carousel-control-hover-border-color:           darken($carousel-control-border-color, 10%) !default;
$carousel-control-hover-color:                  $gray-800 !default;
$carousel-control-hover-bg:                     $white !default;
$carousel-control-transition:                   all .3s ease-in-out !default;


// Accordion

$accordion-bg:                                  transparent !default;
$accordion-border-color:                        $border-light-color !default;
$accordion-button-color:                        $white !default;
$accordion-button-active-bg:                    $accordion-bg !default;
$accordion-button-active-color:                 $white !default;
$accordion-body-padding-y:                      $spacer * 1.5 !default;
$accordion-body-padding-x:                      0 !default;
$accordion-button-padding-y:                    $spacer * 1.5 !default;
$accordion-button-padding-x:                    0 !default;
$accordion-button-font-weight:                  $font-weight-semi-bold !default;

$accordion-icon-width:                          1.5rem !default;
$accordion-icon-color:                          $primary !default;
$accordion-icon-active-color:                   $primary !default;

$accordion-button-icon:                         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'><path d='M13.3327 7.99984c0 .33333-.3334.66666-.6667.66666H8.66602v4c0 .3333-.33334.6667-.66667.6667s-.66667-.3334-.66667-.6667v-4h-4c-.33333 0-.66666-.33333-.66666-.66666s.33333-.66667.66666-.66667h4v-4c0-.33333.33334-.66667.66667-.66667s.66667.33334.66667.66667v4H12.666c.3333 0 .6667.33333.6667.66667z' fill='#{$accordion-icon-color}'/></svg>") !default;
$accordion-button-active-icon:                  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'><path d='M12.666 8.66683H3.33268c-.4 0-.66666-.26667-.66666-.66667s.26666-.66666.66666-.66666H12.666c.4 0 .6667.26666.6667.66666s-.2667.66667-.6667.66667z' fill='#{$accordion-icon-active-color}'/></svg>") !default;

