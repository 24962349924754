//
// Dropdown
// --------------------------------------------------


// Caret

.dropdown-toggle {
  &::after,
  &::before {
    display: inline-block;
    font: {
      family: $icons-font-family;
      weight: normal;
    }
    vertical-align: middle;
  }

  &::after {
    margin: {
      top: -.0625rem;
      left: .25rem;
    }
    border: 0;
    content: $pi-chevron-down;
  }

  &::before { margin-right: .25rem; }
}

.dropdown-toggle:not(.dropdown-toggle-split) {
  &::before { margin-left: -.25rem; }
  &::after { margin-right: -.25rem; }
}

.dropdown .dropdown-toggle,
.dropup .dropdown-toggle,
.dropend .dropdown-toggle,
.dropstart .dropdown-toggle {
  &::before,
  &::after {
    vertical-align: middle;
    border: 0;
  }
}

.dropdown .dropdown-toggle,
.dropup .dropdown-toggle,
.dropend .dropdown-toggle {
  &::before { display: none; }
}

.dropup .dropdown-toggle::after {
  content: $pi-chevron-up;
}

.dropend .dropdown-toggle::after {
  content: $pi-chevron-right;
  vertical-align: bottom;
}

.dropstart .dropdown-toggle {
  &::before {
    content: $pi-chevron-left;
    vertical-align: bottom;
  }
  &::after { display: none; }
}
