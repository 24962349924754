//
// Accordion
// --------------------------------------------------


// General

.accordion-button {
  font-weight: $accordion-button-font-weight;
  box-shadow: initial !important;
}

.accordion-body {
  padding-top: initial;
}
