//
// Icon box
// --------------------------------------------------


// General

.icon-box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $icon-box-width;
  height: $icon-box-width;
  transition: $icon-box-transition;
  border-radius: $icon-box-border-radius;
  background: {
    position: center;
    size: cover;
  }
  font-size: $font-size-base * 2;
  font-weight: $font-weight-semi-bold;
  text-align: center;
  transform: translateZ(0);

  [class^='pi-'], [class*=' pi-'] {
    font-size: $icon-box-font-size;
  }

  &.icon-box-lg {
    width: $icon-box-lg-width;
    height: $icon-box-lg-width;
    font-size: $font-size-base * 2.5;

    [class^='pi-'], [class*=' pi-'] {
      font-size: $icon-box-lg-font-size;
    }
  }

  &.icon-box-sm {
    width: $icon-box-sm-width;
    height: $icon-box-sm-width;
    font-size: $font-size-base * 1.625;

    [class^='pi-'], [class*=' pi-'] {
      font-size: $icon-box-sm-font-size;
    }
  }
}


// Media

@each $color, $value in map-remove($theme-colors, 'secondary', 'light') {
  .icon-box-media:hover {
    .icon-box {
      color: $white !important;
      &.bg-faded-#{$color} {
        background-color: $value !important;
        box-shadow: 0 .5rem 1.5rem rgba($value, .4),
                    0 .125rem .375rem rgba($value, .14),
                    0 0 .0625rem rgba(0, 0, 0, .04);
      }
    }
  }
}
