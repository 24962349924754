//
// Font icons
// generated with https://icomoon.io/app
// --------------------------------------------------

$pi-arrow-down: '\e900';
$pi-arrow-down-left: '\e901';
$pi-arrow-down-right: '\e902';
$pi-arrow-left: '\e903';
$pi-arrow-right: '\e904';
$pi-arrow-up: '\e905';
$pi-arrow-up-left: '\e906';
$pi-arrow-up-right: '\e907';
$pi-automation: '\e908';
$pi-automation-alt: '\e909';
$pi-bulb: '\e90a';
$pi-chevron-down: '\e90b';
$pi-chevron-left: '\e90c';
$pi-chevron-right: '\e90d';
$pi-chevron-up: '\e90e';
$pi-circle: '\e90f';
$pi-clock: '\e910';
$pi-devops: '\e911';
$pi-docker: '\e912';
$pi-eye: '\e913';
$pi-eye-off: '\e914';
$pi-file-text: '\e915';
$pi-like: '\e916';
$pi-mail: '\e917';
$pi-map-pin: '\e918';
$pi-minus: '\e919';
$pi-phone: '\e91a';
$pi-plus: '\e91b';
$pi-refresh: '\e91c';
$pi-settings: '\e91d';
$pi-shield: '\e91e';
$pi-star: '\e91f';
$pi-star-filled: '\e920';
$pi-technical-support: '\e921';
$pi-users: '\e922';
$pi-web-development: '\e923';
$pi-check: '\e924';
$pi-check-circle: '\e925';
$pi-devops-alt: '\e926';
$pi-like-alt: '\e927';
$pi-file-search: '\e928';
$pi-contract: '\e929';
$pi-process: '\e92a';
$pi-idea: '\e92b';
$pi-consulting: '\e92c';

@font-face {
  font-family: '#{$icons-font-family}';
  src:
    url('#{$icons-font-path}/#{$icons-font-family}.ttf?kr949c') format('truetype'),
    url('#{$icons-font-path}/#{$icons-font-family}.woff?kr949c') format('woff'),
    url('#{$icons-font-path}/#{$icons-font-family}.svg?kr949c##{$icons-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='pi-'], [class*=' pi-'] {
  display: inline-block;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icons-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pi-arrow-down {
  &:before {
    content: $pi-arrow-down;
  }
}
.pi-arrow-down-left {
  &:before {
    content: $pi-arrow-down-left;
  }
}
.pi-arrow-down-right {
  &:before {
    content: $pi-arrow-down-right;
  }
}
.pi-arrow-left {
  &:before {
    content: $pi-arrow-left;
  }
}
.pi-arrow-right {
  &:before {
    content: $pi-arrow-right;
  }
}
.pi-arrow-up {
  &:before {
    content: $pi-arrow-up;
  }
}
.pi-arrow-up-left {
  &:before {
    content: $pi-arrow-up-left;
  }
}
.pi-arrow-up-right {
  &:before {
    content: $pi-arrow-up-right;
  }
}
.pi-automation {
  &:before {
    content: $pi-automation;
  }
}
.pi-automation-alt {
  &:before {
    content: $pi-automation-alt;
  }
}
.pi-bulb {
  &:before {
    content: $pi-bulb;
  }
}
.pi-chevron-down {
  &:before {
    content: $pi-chevron-down;
  }
}
.pi-chevron-left {
  &:before {
    content: $pi-chevron-left;
  }
}
.pi-chevron-right {
  &:before {
    content: $pi-chevron-right;
  }
}
.pi-chevron-up {
  &:before {
    content: $pi-chevron-up;
  }
}
.pi-circle {
  &:before {
    content: $pi-circle;
  }
}
.pi-clock {
  &:before {
    content: $pi-clock;
  }
}
.pi-devops {
  &:before {
    content: $pi-devops;
  }
}
.pi-docker {
  &:before {
    content: $pi-docker;
  }
}
.pi-eye {
  &:before {
    content: $pi-eye;
  }
}
.pi-eye-off {
  &:before {
    content: $pi-eye-off;
  }
}
.pi-file-text {
  &:before {
    content: $pi-file-text;
  }
}
.pi-like {
  &:before {
    content: $pi-like;
  }
}
.pi-mail {
  &:before {
    content: $pi-mail;
  }
}
.pi-map-pin {
  &:before {
    content: $pi-map-pin;
  }
}
.pi-minus {
  &:before {
    content: $pi-minus;
  }
}
.pi-phone {
  &:before {
    content: $pi-phone;
  }
}
.pi-plus {
  &:before {
    content: $pi-plus;
  }
}
.pi-refresh {
  &:before {
    content: $pi-refresh;
  }
}
.pi-settings {
  &:before {
    content: $pi-settings;
  }
}
.pi-shield {
  &:before {
    content: $pi-shield;
  }
}
.pi-star {
  &:before {
    content: $pi-star;
  }
}
.pi-star-filled {
  &:before {
    content: $pi-star-filled;
  }
}
.pi-technical-support {
  &:before {
    content: $pi-technical-support;
  }
}
.pi-users {
  &:before {
    content: $pi-users;
  }
}
.pi-web-development {
  &:before {
    content: $pi-web-development;
  }
}
.pi-check {
  &:before {
    content: $pi-check;
  }
}
.pi-check-circle {
  &:before {
    content: $pi-check-circle;
  }
}
.pi-devops-alt {
  &:before {
    content: $pi-devops-alt;
  }
}
.pi-like-alt {
  &:before {
    content: $pi-like-alt;
  }
}
.pi-file-search {
  &:before {
    content: $pi-file-search;
  }
}
.pi-contract {
  &:before {
    content: $pi-contract;
  }
}
.pi-process {
  &:before {
    content: $pi-process;
  }
}
.pi-idea {
  &:before {
    content: $pi-idea;
  }
}
.pi-consulting {
  &:before {
    content: $pi-consulting;
  }
}
