//
// Forms
// --------------------------------------------------


// General

.form-control:disabled,
.form-select:disabled {
  cursor: not-allowed;
}


// Labels

.form-label-sm { padding-left: $input-padding-x-sm; }
.form-label    { padding-left: $input-padding-x; }
.form-label-lg { padding-left: $input-padding-x-lg; }
.form-check-label { font-size: $form-check-label-font-size; }


// Label for use with horizontal and inline forms

.col-form-label {
  @include font-size($form-label-font-size);
}


// Remove default -webkit search input clear button

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}


// Switches

.form-switch {
  .form-check-input {
    height: $form-switch-height;
    border: 0;
    background-color: $form-switch-bg;
    &:checked {
      background-color: $form-switch-checked-bg;
    }
  }
}


// Form validation

.valid-tooltip,
.invalid-tooltip {
  position: static;
}

.valid-tooltip {
  color: $success;
}
.invalid-tooltip {
  color: $danger;
}

.was-validated :valid ~ .valid-tooltip,
.was-validated :invalid ~ .invalid-tooltip,
.is-valid ~ .valid-tooltip,
.is-invalid ~ .invalid-tooltip {
  display: inline-block;
}

// Form validation

.was-validated {
  .form-control:invalid,
  .form-control.is-invalid,
  .form-check-input:invalid,
  .form-check-input.is-invalid {
    &~.invalid-tooltip { display: table; }
  }
  .form-control:valid,
  .form-control.is-valid,
  .form-check-input:valid,
  .form-check-input.is-valid {
    &~.valid-tooltip { display: table; }
  }
  .form-control:valid,
  .form-control.is-valid,
  .form-select:valid,
  .form-select.is-valid {
    border-color: $input-border-color !important;
  }
  .form-check-input:not(:checked):valid,
  .form-check-input:not(:checked).is-valid {
    border-color: $input-border-color !important;
  }
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: transparent;
  border-color: $gray-500;
}
.was-validated .form-check-input:valid ~ .form-check-label,
.was-validated .form-check-input.is-valid ~ .form-check-label {
  color: inherit;
}  
.was-validated .form-check-input:valid:checked,
.was-validated .form-check-input.is-valid:checked {
  border-color: $form-check-input-checked-border-color;
  background-color: $form-check-input-checked-bg-color;
  box-shadow: none;
}


// Password visibility toggle

.password-toggle {
  position: relative;
  .form-control {
    padding-right: $font-size-base + ($spacer * 2);
  }
}

.password-toggle-btn {
  position: absolute;
  top: 50%;
  right: .625rem;
  margin-bottom: 0;
  padding: $spacer * .5;
  transform: translateY(-50%);
  font-size: $btn-font-size-lg;
  line-height: 1;
  cursor: pointer;

  .password-toggle-indicator {
    transition: color .2s ease-in-out;
    color: $input-placeholder-color;
    font: {
      family: $icons-font-family;
      style: normal;
    }
    &::before { content: $pi-eye; }
    &:hover { color: $input-color; }
  }

  .password-toggle-check {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;

    &:checked ~ .password-toggle-indicator::before {
      content: $pi-eye-off;
    }
  }
}
.was-validated .password-toggle .form-control:invalid,
.was-validated .password-toggle .form-control.is-invalid,
.was-validated .password-toggle .form-control:valid,
.was-validated .password-toggle .form-control.is-valid {
  padding-right: 4rem;
  & + .password-toggle-btn { right: 2.1rem; }
}


// Form select options color fix
.form-select option {
  color: $dark;
}
