//
// Typography
// --------------------------------------------------


// Headings

h1, .h1 {
  line-height: $h1-line-height;
}
h2, .h2 {
  line-height: $h2-line-height;
}
h3, .h3 {
  line-height: $h3-line-height;
}
h4, .h4 {
  line-height: $h4-line-height;
}
h5, .h5 {
  line-height: $h5-line-height;
}
h6, .h6 {
  line-height: $h6-line-height;
}


// Gradient text

.text-gradient {
  background: linear-gradient(#fdae66, #fd6d71, #ff444e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
