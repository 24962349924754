//
// Reboot
// --------------------------------------------------

// Import Google Fonts (Manrope)
@import url($path-to-fonts);

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Sticky footer
// html, body {
//   height: 100%;
// }
// body {
//   display: flex;
//   flex-direction: column;
// }
// .page-wrapper {
//   flex: 1 0 auto;
// }
// .footer {
//   flex-shrink: 0;
// }


// Links
a {
  transition: $link-transition;
  &:focus { outline: none; }
}

// Background transition for links
a[class^='bg-']:not(.btn),
a[class*='bg-']:not(.btn) {
  transition: background-color .25s ease;
}


// Images
// Responsive images (ensure images don't scale beyond their parents)
img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
svg { max-width: 100%; }
.dark-mode-img { display: none; }

// Responsive iframes
iframe {
  width: 100%;
}

// Text Selection Color
::selection {
  background: $user-selection-color;
}
::-moz-selection {
  background: $user-selection-color;
}

b,
strong {
  font-weight: $font-weight-bold;
}

small { font-weight: inherit; }

// Disable button :focus outline
button:focus {
  outline: none;
}

// Vertical divider
.hr-vertical {
  width: $border-width;
  height: 100% !important;
}

// Listing grid
.listing-grid {
  margin: {
    right: -$border-width;
    bottom: -$border-width;
  }
}

// Remove table borders
.table.border-0 > :not(caption) > * > * {
  border-width: 0;
}


// Ratio
.ratio {
  > video, > img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
