//
// Navbar
// --------------------------------------------------


.navbar {
  padding: {
    top: $spacer * .875;
    bottom: $spacer * .875;
  }
  background-color: $dark;
  transition: all .25s;
}


// Styles for expanded navbar only
//---------------------------------------------------

.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-up($next) {
        padding: {
          top: $navbar-padding-y;
          bottom: $navbar-padding-y;
        }
        background-color: transparent;

        &.navbar-stuck {
          background-color: $dark;
        }
      }
    }
  }
}
