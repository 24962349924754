//
// Buttons
// --------------------------------------------------


// Overriding button variant colors
//
// Solid variant

@each $color, $value in map-remove($theme-colors, 'light') {
  .btn-#{$color} {
    color: $btn-dark-color;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.show {
      background-color: darken($value, 7.5%);
      border-color: darken($value, 7.5%);
      color: $btn-dark-color;
    }

    &:disabled,
    &.disabled {
      color: $btn-dark-color;
    }
  }
}

.btn-secondary {
  &,
  &:hover,
  &:focus,
  &:active,
  &.active,
  &.show {
    color: $gray-700;
  }

  &:disabled,
  &.disabled {
    color: $btn-light-color;
  }
}

.btn-light {
  border-color: transparent;
  background-color: $light;
  color: $btn-light-color;

  &:hover,
  &:focus,
  &:active,
  &.active {
    border-color: $light;
    background-color: $light;
  }
}


// Outline variant

[class^='btn-outline-'],
[class*=' btn-outline-'] {
  &:hover, &:active,
  &.active, &.dropdown-toggle.show {
    color: $btn-dark-color;
  }
}

@each $color, $value in $border-colors {
  .btn-outline-#{$color} {
    border-color: $value;
  }
}

.btn-outline-secondary {
  border-color: $gray-400;
  color: $btn-light-color;

  &:hover, &:focus, &:active,
  &.active, &.show {
    border-color: $gray-500;
    background-color: transparent;
    color: $gray-800 !important;
  }
}

.btn-outline-light {
  border-color: rgba($light, .35);
  &:hover, &:focus, &:active,
  &.active, &.show {
    background-color: $light;
    color: $gray-800;
  }
}


// Adding box-shadow to all solid buttons

@each $color, $value in $theme-colors {
  @if $color != 'secondary' or $color != 'light' {
    .btn-#{$color}.btn-shadow {
      box-shadow: 0 .5rem 1.5rem rgba($value, .14),
                  0 .125rem .375rem rgba($value, .14),
                  0 0 .0625rem rgba($black, .04);
    }
  }
}
.btn-secondary,
.btn-light {
  &.btn-shadow {
    box-shadow: 0 .5rem 1.125rem -.5rem rgba($black, .15);
  }
}
.btn-shadow:hover { box-shadow: none; }


// Link button

.btn-link {
  font-weight: $btn-font-weight;
  text-decoration: $btn-link-decoration;
  
  &:hover,
  &:focus,
  &:active,
  &.active,
  &.show {
    text-decoration: $btn-link-hover-decoration;
  }
}


// Icons
//
// Alignment

.btn {
  > [class^='pi-'],
  > [class*=' pi-'] {
    margin-top: -.125rem;
    vertical-align: middle;
    @include font-size($btn-icon-size);
  }
}

// Icon button

.btn-icon { padding: $btn-padding-y ($btn-padding-y * 1.4); }


// Scroll to top button

.btn-scroll-top {
  display: block;
  position: fixed;
  right: -($btn-scroll-top-size * 1.5);
  bottom: $spacer * 1.25;
  width: $btn-scroll-top-size;
  height: $btn-scroll-top-size;
  transition: $btn-scroll-top-transition;
  @include border-radius($btn-border-radius);
  background-color: $btn-scroll-top-bg;
  color: $btn-scroll-top-color;
  text-align: center;
  opacity: 0;
  z-index: $zindex-btn-scroll-top;

  > .btn-scroll-top-icon {
    font: {
      size: $btn-scroll-top-icon-font-size;
      weight: bold;
    }
    line-height: $btn-scroll-top-size - .125rem;
  }

  .btn-scroll-top-tooltip {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
    visibility: hidden;
    opacity: 0;
  }

  &:hover {
    background-color: $btn-scroll-top-hover-bg;
    color: $btn-scroll-top-hover-color;
    .btn-scroll-top-tooltip {
      visibility: visible;
      opacity: 1;
    }
  }

  &.show {
    right: $spacer * 1.25;
    opacity: 1;
  }

  @include media-breakpoint-down(sm) {
    width: $btn-scroll-top-size * .833;
    height: $btn-scroll-top-size * .833;
    bottom: $spacer;
    > .btn-scroll-top-icon {
      line-height: $btn-scroll-top-size * .833;
    }
    &.show { right: $spacer; }
  }
}
